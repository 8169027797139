import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link, useNavigate, useLocation } from 'react-router-dom';
import { Menu } from 'lucide-react';
import logoMarketResearch from './Images/Market-Research-logo1.svg';
import './MarketResearch.css';
import MobileMenuMarketResearch from './MobileMenuMarketResearch.js';
import GoogleCustomSearch from './GoogleCustomSearch';

const MarketResearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < window.screen.width * 0.75);
    };
    
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleInventoryClick = () => {
    window.location.href = 'https://daxtop.com/inventory/dashboard';
  };

  const isSearchPage = location.pathname === '/search';


  const categories = [
 {
      name: "Demographic Segmentation",
      subcategories: ["Age", "Gender", "Income", "Education level", "Occupation", "Family size", "Life cycle stage"]
 },
 {
      name: "Geographic Segmentation",
      subcategories: ["Location", "Climate", "Population density", "Regional preferences and needs"]
 },
 {
      name: "Psychographic Segmentation",
      subcategories: ["Lifestyle", "Social class", "Personality traits", "Values and beliefs", "Interests and activities"]
 },
 {
      name: "Behavioral Segmentation",
      subcategories: ["Purchasing behavior", "Usage rate", "Brand loyalty", "Benefits sought", "User status"]
 },
 {
      name: "Firmographic Segmentation",
      subcategories: ["Industry", "Company size", "Business location", "Organizational structure", "Purchase behavior"]
 },
 {
      name: "Technographic Segmentation",
      subcategories: ["Technology usage and adoption", "Technology preferences", "Digital behavior"]
 }
 ];

  const toggleCategory = (categoryName) => {
    setActiveCategory(activeCategory === categoryName ? null : categoryName);
    setActiveSubcategory(null);
 };

  const selectSubcategory = (subcategory) => {
    setActiveSubcategory(subcategory);
 };

  return (
    <div className="market-research-page">
    <nav className='market-research-navbar'>
      <div className="navbar-container">
        <div className="navbar-left">
          <div className="navbar-logo">
            <Link to="/search">
              <img src={logoMarketResearch} alt="DAXTOP MARKET" />
            </Link>
          </div>
          {!isMobileView && (
            <ul className="navbar-links">
              <li><Link to="/search">Search</Link></li>
              <li><Link to="/segmentation">Segmentation</Link></li>
              <li><Link to="/analysis">Analysis</Link></li>
              <li><Link to="/reports">Reports</Link></li>
              <li><Link to="/tools">Tools</Link></li>
            </ul>
          )}
        </div>
        <div className="navbar-actions">
          {isMobileView ? (
            <button className="mobile-menu-toggle" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
              <Menu size={24} />
            </button>
          ) : (
            <button className="inventory-btn" onClick={handleInventoryClick}>Go to Inventory</button>
          )}
        </div>
      </div>
    </nav>
    <MobileMenuMarketResearch isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)} />
    <div className={`market-research-content ${isSearchPage ? 'search-page' : ''}`}>
      {!isSearchPage && (
        <aside className="sidebar">
          <h2>Research Categories</h2>
          <ul className="category-list">
            {categories.map((category) => (
              <li key={category.name}>
                <button onClick={() => toggleCategory(category.name)} className="category-button">
                  {activeCategory === category.name ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
                  {category.name}
                </button>
                {activeCategory === category.name && (
                  <ul className="subcategory-list">
                    {category.subcategories.map((subcategory) => (
                      <li key={subcategory}>
                        <button 
                          onClick={() => selectSubcategory(subcategory)}
                          className={`subcategory-button ${activeSubcategory === subcategory ? 'active' : ''}`}
                        >
                          {subcategory}
                        </button>
                      </li>
 ))}
                  </ul>
 )}
              </li>
 ))}
          </ul>
        </aside>
               )}
               <main className={`research-main ${isSearchPage ? 'full-width' : ''}`}>
                 <Routes>
                   <Route path="/" element={<Navigate to="/search" replace />} />
                   <Route path="/search" element={<GoogleCustomSearch />} />
                   <Route path="/segmentation" element={<h1>Segmentation</h1>} />
                   <Route path="/analysis" element={<h1>Analysis</h1>} />
                   <Route path="/reports" element={<h1>Reports</h1>} />
                   <Route path="/tools" element={<h1>Tools</h1>} />
                 </Routes>
               </main>
             </div>
           </div>
    
 );
};

export default MarketResearch;
