import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import "./EmployeeDetailsForm.css"

const PinConfirmation = ({ isOpen, onClose, onConfirm, action }) => {
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const result = await onConfirm(pin, action);
    if (!result.success) {
      setError(result.message);
    } else {
      setPin('');
      onClose();
    }
  };

  const handleCancel = () => {
    setPin('');
    setError('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="pin-confirmation-overlay">
      <div className="pin-confirmation-container">
        <h3>Confirm Action</h3>
        <p>Please enter your PIN to {action === 'save' ? 'save changes' : 'delete employee'}:</p>
        <form onSubmit={handleSubmit}>
          <input
            className="form-group-input-a"
            type="password"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            placeholder="Enter PIN"
            required
          />
          {error && <p className="error-message">{error}</p>}
          <div className="form-actions">
            <button type="submit" className="button-confirm">Confirm</button>
            <button type="button" className="button-cancel" onClick={handleCancel}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

const EmployeeDetailsForm = ({ employee, modifiedBrandName, onClose, onUpdate, onDelete }) => {
  const [employeeData, setEmployeeData] = useState(employee);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showPinConfirmation, setShowPinConfirmation] = useState(false);
  const [pinAction, setPinAction] = useState(null);

  useEffect(() => {
    setEmployeeData(employee);
  }, [employee]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedEmployee = { ...employeeData };

    if (type === 'checkbox') {
      updatedEmployee[name] = checked;
    } else {
      updatedEmployee[name] = value;
    }

    // Synchronize fields based on employment type and payment type
    if (name === 'employmentType') {
      updatedEmployee.endDateNA = value === 'fullTime' || value === 'partTime';
      updatedEmployee.endDate = updatedEmployee.endDateNA ? 'N/A' : '';
    }

    if (name === 'paymentType') {
      // Reset all payment-related fields
      updatedEmployee.salary = '';
      updatedEmployee.hourlyRate = '';
      updatedEmployee.commissionRate = '';
      updatedEmployee.pieceRate = '';
      updatedEmployee.stipendAmount = '';
      updatedEmployee.retainerAmount = '';
      updatedEmployee.projectFee = '';
      updatedEmployee.performanceMetrics = '';
      
      // Set relevant fields based on payment type
      switch (value) {
        case 'hourly':
          updatedEmployee.payrollSchedule = 'weekly';
          break;
        case 'salary':
          updatedEmployee.payrollSchedule = 'monthly';
          break;
        case 'commission':
          updatedEmployee.payrollSchedule = 'monthly';
          break;
        case 'pieceRate':
          updatedEmployee.payrollSchedule = 'weekly';
          break;
        case 'projectBased':
          updatedEmployee.payrollSchedule = 'onCompletion';
          break;
        default:
          updatedEmployee.payrollSchedule = '';
      }
    }

    setEmployeeData(updatedEmployee);
  };

  const handlePinConfirm = async (enteredPin, action) => {
    try {
      const db = getFirestore();
      const accountingRef = doc(db, `stores/${modifiedBrandName}/finances/accounting`);
      const accountingDoc = await getDoc(accountingRef);
      
      if (accountingDoc.exists() && accountingDoc.data().pinCode === enteredPin) {
        if (action === 'save') {
          await saveChanges();
        } else if (action === 'delete') {
          await deleteEmployee();
        }
        return { success: true };
      } else {
        return { success: false, message: "Incorrect PIN. Please try again." };
      }
    } catch (err) {
      console.error(err);
      return { success: false, message: `Failed to ${action === 'save' ? 'update' : 'delete'} employee data` };
    }
  };

  const saveChanges = async () => {
    const db = getFirestore();
    const employeeRef = doc(db, `stores/${modifiedBrandName}/finances/accounting/employees/${employee.id}`);
    await updateDoc(employeeRef, employeeData);
    onUpdate(employeeData);
    setIsEditing(false);
  };

  const deleteEmployee = async () => {
    const db = getFirestore();
    const employeeRef = doc(db, `stores/${modifiedBrandName}/finances/accounting/employees/${employee.id}`);
    await deleteDoc(employeeRef);
    onDelete(employee.id);
  };

  const handleSaveClick = () => {
    setPinAction('save');
    setShowPinConfirmation(true);
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();
    setPinAction('delete');
    setShowPinConfirmation(true);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    if (isEditing) {
      setEmployeeData(employee);
      setIsEditing(false);
    } else {
      onClose();
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="employee-details-form">
      <h2>{isEditing ? "Edit Employee" : "Employee Profile"}</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            className="form-group-input-a"
            id="name"
            name="name"
            value={employeeData.name}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="surname">Surname:</label>
          <input
            className="form-group-input-a"
            id="surname"
            name="surname"
            value={employeeData.surname}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="maritalStatus">Marital Status:</label>
          <select
            className="form-group-input-a"
            id="maritalStatus"
            name="maritalStatus"
            value={employeeData.maritalStatus}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          >
            <option value="">Select Marital Status</option>
            <option value="single">Single</option>
            <option value="marriedJoint">Married Filing Jointly</option>
            <option value="marriedSeparate">Married Filing Separately</option>
            <option value="headOfHousehold">Head of Household</option>
            <option value="widower">Qualifying Widow(er) with Dependent Child</option>
            <option value="n/a">N/A</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="position">Position:</label>
          <input
            className="form-group-input-a"
            id="position"
            name="position"
            value={employeeData.position}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="department">Department:</label>
          <input
            className="form-group-input-a"
            id="department"
            name="department"
            value={employeeData.department}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="employmentType">Employment Type:</label>
          <select
            className="form-group-input-a"
            id="employmentType"
            name="employmentType"
            value={employeeData.employmentType}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          >
            <option value="">Select Employment Type</option>
            <option value="fullTime">Full-time</option>
            <option value="partTime">Part-time</option>
            <option value="contract">Contract</option>
            <option value="freelance">Freelance</option>
            <option value="temporary">Temporary</option>
            <option value="internship">Internship</option>
            <option value="seasonal">Seasonal</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="paymentType">Payment Type:</label>
          <select
            className="form-group-input-a"
            id="paymentType"
            name="paymentType"
            value={employeeData.paymentType}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          >
            <option value="">Select Payment Type</option>
            <option value="hourly">Hourly</option>
            <option value="salary">Salary</option>
            <option value="commission">Commission</option>
            <option value="pieceRate">Piece Rate</option>
            <option value="projectBased">Project-based</option>
          </select>
        </div>

        {/* Conditional fields based on payment type */}
        {employeeData.paymentType === 'hourly' && (
          <>
            <div className="form-group">
              <label htmlFor="hourlyRate">Hourly Rate:</label>
              <input
                className="form-group-input-a"
                id="hourlyRate"
                name="hourlyRate"
                type="number"
                value={employeeData.hourlyRate}
                onChange={handleInputChange}
                disabled={!isEditing}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="hoursPerDay">Hours per Day:</label>
              <input
                className="form-group-input-a"
                id="hoursPerDay"
                name="hoursPerDay"
                type="number"
                value={employeeData.hoursPerDay}
                onChange={handleInputChange}
                disabled={!isEditing}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="daysPerWeek">Days per Week:</label>
              <input
                className="form-group-input-a"
                id="daysPerWeek"
                name="daysPerWeek"
                type="number"
                value={employeeData.daysPerWeek}
                onChange={handleInputChange}
                disabled={!isEditing}
                required
              />
            </div>
          </>
        )}

        {employeeData.paymentType === 'salary' && (
          <div className="form-group">
            <label htmlFor="salary">Monthly Salary:</label>
            <input
              className="form-group-input-a"
              id="salary"
              name="salary"
              type="number"
              value={employeeData.salary}
              onChange={handleInputChange}
              disabled={!isEditing}
              required
            />
          </div>
        )}

        {employeeData.paymentType === 'commission' && (
          <>
            <div className="form-group">
              <label htmlFor="salary">Base Salary:</label>
              <input
                className="form-group-input-a"
                id="salary"
                name="salary"
                type="number"
                value={employeeData.salary}
                onChange={handleInputChange}
                disabled={!isEditing}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="commissionRate">Commission Rate (%):</label>
              <input
                className="form-group-input-a"
                id="commissionRate"
                name="commissionRate"
                type="number"
                value={employeeData.commissionRate}
                onChange={handleInputChange}
                disabled={!isEditing}
                required
              />
            </div>
          </>
        )}

        {employeeData.paymentType === 'pieceRate' && (
          <div className="form-group">
            <label htmlFor="pieceRate">Rate per Piece:</label>
            <input
              className="form-group-input-a"
              id="pieceRate"
              name="pieceRate"
              type="number"
              value={employeeData.pieceRate}
              onChange={handleInputChange}
              disabled={!isEditing}
              required
            />
          </div>
        )}

        {employeeData.paymentType === 'projectBased' && (
          <>
            <div className="form-group">
              <label htmlFor="projectFee">Project Fee:</label>
              <input
                className="form-group-input-a"
                id="projectFee"
                name="projectFee"
                type="number"
                value={employeeData.projectFee}
                onChange={handleInputChange}
                disabled={!isEditing}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="projectEndDate">Project End Date:</label>
              <input
                className="form-group-input-a"
                id="projectEndDate"
                name="projectEndDate"
                type="date"
                value={employeeData.projectEndDate}
                onChange={handleInputChange}
                disabled={!isEditing}
                required
              />
            </div>
          </>
        )}

        <div className="form-group">
          <label htmlFor="payrollSchedule">Payroll Schedule:</label>
          <select
            className="form-group-input-a"
            id="payrollSchedule"
            name="payrollSchedule"
            value={employeeData.payrollSchedule}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          >
            <option value="">Select Payroll Schedule</option>
            <option value="weekly">Weekly</option>
            <option value="biweekly">Bi-weekly</option>
            <option value="semimonthly">Semi-monthly</option>
            <option value="monthly">Monthly</option>
            {employeeData.paymentType === 'projectBased' && (
              <option value="onCompletion">On Project Completion</option>
            )}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="startDate">Start Date:</label>
          <input
            className="form-group-input-a"
            id="startDate"
            name="startDate"
            type="date"
            value={employeeData.startDate}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="endDate">End Date:</label>
          <input
            className="form-group-input-a"
            id="endDate"
            name="endDate"
            type="date"
            value={employeeData.endDate}
            onChange={handleInputChange}
            disabled={!isEditing || employeeData.endDateNA}
            required={!employeeData.endDateNA}
          />
          <label>
            <input
              type="checkbox"
              name="endDateNA"
              checked={employeeData.endDateNA}
              onChange={handleInputChange}
              disabled={!isEditing}
            /> N/A
          </label>
        </div>

        <div className="form-actions">
          {isEditing ? (
            <>
              <button type="button" className="button-confirm" onClick={handleSaveClick}>Save Changes</button>
              <button type="button" className="button-cancel" onClick={handleCancelClick}>Cancel</button>
            </>
          ) : (
            <>
              <button type="button" className="button-confirm" onClick={handleEditClick}>Edit</button>
              <button type="button" className="button-cancel" onClick={handleDeleteClick}>Delete</button>
              <button type="button" className="button-cancel" onClick={onClose}>Close</button>
            </>
          )}
        </div>
      </form>
      <PinConfirmation
        isOpen={showPinConfirmation}
        onClose={() => setShowPinConfirmation(false)}
        onConfirm={handlePinConfirm}
        action={pinAction}
      />
    </div>
  );
};

export default EmployeeDetailsForm;