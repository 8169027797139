import React, { useState, useEffect, useRef } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, collection, query, getDocs, where, addDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './Accounting.css';
import EmployeeDetailsForm from './EmployeeDetailsForm';

const INACTIVITY_TIMEOUT = 5 * 60 * 1000;

const Accounting = () => {
  const [user, setUser] = useState(null);
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const [companyInfo, setCompanyInfo] = useState({
    name: '',
    surname: '',
    type: '',
    country: '',
    state: '',
    fiscalYearEnd: '',
    currency: '',
 });
  const [financialData, setFinancialData] = useState({
    revenue: 0,
    costOfGoodsSold: 0,
    operatingExpenses: {
      rent: 0,
      utilities: 0,
      marketing: 0,
      other: 0,
 },
    otherIncome: 0,
    otherExpenses: 0,
 });
  const [taxRates, setTaxRates] = useState({
    incomeTax: 0,
    salesTax: 0,
    payrollTax: 0,
 });
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pinCode, setPinCode] = useState('');
  const [isPinSet, setIsPinSet] = useState(false);
  const [enteredPin, setEnteredPin] = useState('');
  const [showPin, setShowPin] = useState(false);
  const [pinError, setPinError] = useState('');
  const [isPinValid, setIsPinValid] = useState(false);
  const [isLocked, setIsLocked] = useState(true);
  const [incorrectAttempts, setIncorrectAttempts] = useState(0);
  const [lockoutTime, setLockoutTime] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [showResetPin, setShowResetPin] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetError, setResetError] = useState('');

  const inactivityTimerRef = useRef(null);
  const visibilityChangeRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user);
        fetchStoreData(user.uid);
 } else {
        setLoading(false);
        setError("Please log in to access accounting data.");
 }
 });


    document.addEventListener('mousemove', resetInactivityTimer);
    document.addEventListener('keypress', resetInactivityTimer);

    visibilityChangeRef.current = handleVisibilityChange;
    document.addEventListener('visibilitychange', visibilityChangeRef.current);

    const storedAttempts = localStorage.getItem('incorrectAttempts');
    const storedLockoutTime = localStorage.getItem('lockoutTime');
    if (storedAttempts) setIncorrectAttempts(parseInt(storedAttempts));
    if (storedLockoutTime) {
      const remainingLockout = parseInt(storedLockoutTime) - Date.now();
      if (remainingLockout > 0) {
        setLockoutTime(parseInt(storedLockoutTime));
        setRemainingTime(remainingLockout);
 }
 }

    return () => {
      unsubscribe();
      document.removeEventListener('mousemove', resetInactivityTimer);
      document.removeEventListener('keypress', resetInactivityTimer);
      document.removeEventListener('visibilitychange', visibilityChangeRef.current);
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
 }
      if (timerRef.current) clearInterval(timerRef.current);
 };
 }, []);

  useEffect(() => {
    if (remainingTime > 0 && !timerRef.current) {
      timerRef.current = setInterval(() => {
        setRemainingTime(prev => {
          const newTime = prev - 1000;
          if (newTime <= 0) {
            clearInterval(timerRef.current);
            timerRef.current = null;
            setLockoutTime(0);
            localStorage.removeItem('lockoutTime');
            return 0;
 }
          return newTime;
 });
 }, 1000);
 }
 }, [remainingTime]);

  const fetchStoreData = async (userId) => {
    setLoading(true);
    setError(null);
    try {
      const db = getFirestore();
      const storesRef = collection(db, 'stores');
      const q = query(storesRef, where('userId', '==', userId));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const storeData = querySnapshot.docs[0].data();
        setModifiedBrandName(storeData.modifiedBrandName);
        await fetchAccountingData(storeData.modifiedBrandName);
 } else {
        setError("No store found for this user.");
 }
 } catch (err) {
      console.error("Error fetching store data:", err);
      setError("Failed to fetch store data. Please try again.");
 } finally {
      setLoading(false);
 }
 };

  

  const fetchAccountingData = async (modifiedBrandName) => {
    try {
      const db = getFirestore();
      const accountingRef = doc(db, `stores/${modifiedBrandName}/finances/accounting`);
      const accountingDoc = await getDoc(accountingRef);

      if (accountingDoc.exists()) {
        const data = accountingDoc.data();
        setCompanyInfo(data.companyInfo || {});
        setFinancialData(data.financialData || {});
        setTaxRates(data.taxRates || {});
        setIsPinSet(!!data.pinCode);
        setPinCode(data.pinCode || '');
 } else {
        await setDoc(accountingRef, {
          companyInfo: {},
          financialData: {},
          taxRates: {},
          pinCode: '',
 });
        setIsPinSet(false);
 }

      await fetchEmployees(modifiedBrandName);
 } catch (err) {
      console.error("Error fetching accounting data:", err);
      setError("Failed to fetch accounting data. Please try again.");
 }
 };

  const fetchEmployees = async (modifiedBrandName) => {
    try {
      const db = getFirestore();
      const employeesRef = collection(db, `stores/${modifiedBrandName}/finances/accounting/employees`);
      const employeesSnapshot = await getDocs(employeesRef);
      const employeesData = employeesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEmployees(employeesData);
 } catch (err) {
      console.error("Error fetching employees:", err);
      setError("Failed to fetch employee data. Please try again.");
 }
 };

  const handlePinChange = (e) => {
    const newPin = e.target.value;
    setEnteredPin(newPin);
    validatePin(newPin);
 };

  const validatePin = (pin) => {
    if (pin.length < 4) {
      setPinError("PIN should be at least 4 characters");
      setIsPinValid(false);
 } else {
      setPinError("");
      setIsPinValid(true);
 }
 };

  const handlePinBlur = () => {
    validatePin(enteredPin);
 };

  const resetInactivityTimer = () => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
 }
    inactivityTimerRef.current = setTimeout(lockPage, INACTIVITY_TIMEOUT);
 };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      lockPage();
 }
 };

  const lockPage = () => {
    setIsLocked(true);
    setEnteredPin('');
 };

  const unlockPage = () => {
    setIsLocked(false);
    resetInactivityTimer();
 };

  const handlePinSubmit = async (e) => {
    e.preventDefault();
    if (!isPinValid || remainingTime > 0) return;

    if (isPinSet) {
      if (enteredPin === pinCode) {
        unlockPage();
        setEnteredPin('');
        setPinError('');
        setIncorrectAttempts(0);
        localStorage.removeItem('incorrectAttempts');
 } else {
        const newAttempts = incorrectAttempts + 1;
        setIncorrectAttempts(newAttempts);
        localStorage.setItem('incorrectAttempts', newAttempts);
        setPinError("Incorrect PIN. Please try again.");

        if (newAttempts % 5 === 0) {
          const lockoutDuration = getLockoutDuration(newAttempts);
          const newLockoutTime = Date.now() + lockoutDuration;
          setLockoutTime(newLockoutTime);
          setRemainingTime(lockoutDuration);
          localStorage.setItem('lockoutTime', newLockoutTime);
 }
 }
 } else {
      try {
        const db = getFirestore();
        const accountingRef = doc(db, `stores/${modifiedBrandName}/finances/accounting`);
        await setDoc(accountingRef, { pinCode: enteredPin }, { merge: true });
        setIsPinSet(true);
        setPinCode(enteredPin);
        setEnteredPin('');
        setPinError('');
        setShowPin(false);
 } catch (err) {
        console.error("Error setting PIN:", err);
        setPinError("Failed to set PIN. Please try again.");
 }
 }
 };

  const getLockoutDuration = (attempts) => {
    const occurence = Math.floor(attempts / 5);
    switch (occurence) {
      case 1: return 60 * 1000;
      case 2: return 5 * 60 * 1000;
      case 3: return 30 * 60 * 1000;
      case 4: return 60 * 60 * 1000;
      case 5: return 4 * 60 * 60 * 1000;
      default: return 24 * 60 * 60 * 1000;
 }
 };

  const handleResetPin = async (e) => {
    e.preventDefault();
    setResetError('');
    const auth = getAuth();
    const db = getFirestore();
    const storesRef = collection(db, 'stores');
    const q = query(storesRef, where('userId', '==', auth.currentUser.uid));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const storeData = querySnapshot.docs[0].data();
      if (storeData.email !== resetEmail) {
        setResetError('Wrong Email');
        return;
 }
 } else {
      setResetError('Email not found');
      return;
 }

    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetError('Password reset email sent. Please check your inbox.');
      setShowResetPin(false);
 } catch (error) {
      setResetError('Failed to send reset email. Please try again.');
 }
 };

  const togglePinVisibility = (e) => {
    e.preventDefault();
    setShowPin(!showPin);
 };

  const saveAccountingData = async () => {
    if (!modifiedBrandName) return;

    setLoading(true);
    setError(null);
    try {
      const db = getFirestore();
      const accountingRef = doc(db, `stores/${modifiedBrandName}/finances/accounting`);
      await setDoc(accountingRef, {
        companyInfo,
        financialData,
        taxRates,
 });
 } catch (err) {
      console.error("Error saving accounting data:", err);
      setError("Failed to save accounting data. Please try again.");
 } finally {
      setLoading(false);
 }
 };

  const addEmployee = async (employeeData) => {
    try {
      const db = getFirestore();
      const employeesRef = collection(db, `stores/${modifiedBrandName}/finances/accounting/employees`);
      await addDoc(employeesRef, employeeData);
      await fetchEmployees(modifiedBrandName);
 } catch (err) {
      console.error("Error adding employee:", err);
      setError("Failed to add employee. Please try again.");
 }
 };

  const updateEmployee = async (employeeId, employeeData) => {
    try {
      const db = getFirestore();
      const employeeRef = doc(db, `stores/${modifiedBrandName}/finances/accounting/employees/${employeeId}`);
      await setDoc(employeeRef, employeeData, { merge: true });
      await fetchEmployees(modifiedBrandName);
 } catch (err) {
      console.error("Error updating employee:", err);
      setError("Failed to update employee. Please try again.");
 }
 };

  const deleteEmployee = async (employeeId) => {
    try {
      const db = getFirestore();
      const employeeRef = doc(db, `stores/${modifiedBrandName}/finances/accounting/employees/${employeeId}`);
      await deleteDoc(employeeRef);
      await fetchEmployees(modifiedBrandName);
 } catch (err) {
      console.error("Error deleting employee:", err);
      setError("Failed to delete employee. Please try again.");
 }
 };

  const calculateTotalSalaries = (startDate, endDate) => {
    return employees.reduce((total, employee) => {
      const salary = parseFloat(employee.salary) || 0;
      const hoursPerDay = parseFloat(employee.hoursPerDay) || 0;
      const daysPerWeek = parseFloat(employee.daysPerWeek) || 0;
      const weeksInPeriod = (endDate - startDate) / (7 * 24 * 60 * 60 * 1000);
      return total + (salary * hoursPerDay * daysPerWeek * weeksInPeriod);
 }, 0);
 };

  const generateFinancialReports = (startDate, endDate) => {
    const totalSalaries = calculateTotalSalaries(startDate, endDate);
    const totalRevenue = financialData.revenue;
    const totalExpenses = Object.values(financialData.operatingExpenses).reduce((a, b) => a + b, 0) + totalSalaries;
    const netProfit = totalRevenue - totalExpenses;

    return (
      <div className="financial-reports">
        <h3 className='h3-finances'>Financial Reports</h3>
        <div className="report">
          <h4 className='h4-finances'>Income Statement</h4>
          <p>Total Revenue: ${totalRevenue.toFixed(2)}</p>
          <p>Total Expenses: ${totalExpenses.toFixed(2)}</p>
          <p>Net Profit: ${netProfit.toFixed(2)}</p>
        </div>
        <div className="report">
          <h4 className='h4-finances'>Employee Expenses</h4>
          <p>Total Salaries: ${totalSalaries.toFixed(2)}</p>
        </div>
      </div>
 );
 };

  if (loading) return <div className="loading">Loading accounting data...</div>;
  if (error) return <div className="error">{error}</div>;

   

  return (
    <div className="accounting-container">
          <h1 className='h1-finances'>Accounting Dashboard</h1>
      <Tabs>
        <TabList>
          <Tab>Company Info</Tab>
          <Tab>Financial Data</Tab>
          <Tab>Employee Management</Tab>
          <Tab>Tax Rates</Tab>
          <Tab>Reports</Tab>
        </TabList>

        <TabPanel>
          <CompanyInfoForm 
            companyInfo={companyInfo} 
            handleChange={(e) => setCompanyInfo({...companyInfo, [e.target.name]: e.target.value})}
            handleSubmit={saveAccountingData}
          />
        </TabPanel>

        <TabPanel>
          <FinancialDataForm 
            financialData={financialData}
            handleChange={(e) => {
              const { name, value } = e.target;
              if (name.includes('.')) {
                const [category, subCategory] = name.split('.');
                setFinancialData({
 ...financialData,
                  [category]: {
 ...financialData[category],
                    [subCategory]: parseFloat(value) || 0,
 },
 });
 } else {
                setFinancialData({ ...financialData, [name]: parseFloat(value) || 0 });
 }
 }}
            handleSubmit={saveAccountingData}
          />
        </TabPanel>

        <TabPanel>
        <EmployeeManagement 
            employees={employees}
            addEmployee={addEmployee}
            updateEmployee={updateEmployee}
            deleteEmployee={deleteEmployee}
            modifiedBrandName={modifiedBrandName}
          />
        </TabPanel>

        <TabPanel>
          <TaxRateForm 
            taxRates={taxRates}
            handleChange={(e) => setTaxRates({...taxRates, [e.target.name]: parseFloat(e.target.value) || 0})}
            handleSubmit={saveAccountingData}
          />
        </TabPanel>

        <TabPanel>
          <ReportGenerator generateFinancialReports={generateFinancialReports} />
        </TabPanel>
      </Tabs>
    </div>
 );
};

const CompanyInfoForm = ({ companyInfo, handleChange, handleSubmit }) => (
  <form onSubmit={handleSubmit} className="company-info-form">
    <h2 className='h2-finances'>Company Information</h2>
    <div className="form-group-a">
      <label htmlFor="name">Company Name:</label>
      <input
        className="form-group-input-a"
        id="name"
        name="name"
        value={companyInfo.name}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group-a">
      <label  htmlFor="type">Company Type:</label>
      <select
        className="form-group-input-a"
        id="type"
        name="type"
        value={companyInfo.type}
        onChange={handleChange}
        required
      >
        <option value="">Select Company Type</option>
        <option value="corporation">Corporation</option>
        <option value="llc">LLC</option>
        <option value="partnership">Partnership</option>
        <option value="sole-proprietorship">Sole Proprietorship</option>
      </select>
    </div>
    <div className="form-group-a">
      <label htmlFor="country">Country:</label>
      <input
      className="form-group-input-a"
        id="country"
        name="country"
        value={companyInfo.country}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="state">State/Province:</label>
      <input
      className="form-group-input-a"
        id="state"
        name="state"
        value={companyInfo.state}
        onChange={handleChange}
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="fiscalYearEnd">Fiscal Year End:</label>
      <input
      className="form-group-input-a"
        id="fiscalYearEnd"
        name="fiscalYearEnd"
        type="date"
        value={companyInfo.fiscalYearEnd}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="currency">Currency:</label>
      <input
      className="form-group-input-a"
        id="currency"
        name="currency"
        value={companyInfo.currency}
        onChange={handleChange}
        required
      />
    </div>
    <button className='button-a' type="submit">Save Company Info</button>
  </form>
);

const FinancialDataForm = ({ financialData, handleChange, handleSubmit }) => (
  <form onSubmit={handleSubmit} className="financial-data-form">
    <h2 className='h2-finances'>Financial Data</h2>
    <div className="form-group-a">
      <label htmlFor="revenue">Revenue:</label>
      <input
      className="form-group-input-a"
        id="revenue"
        name="revenue"
        type="number"
        value={financialData.revenue}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="costOfGoodsSold">Cost of Goods Sold:</label>
      <input
      className="form-group-input-a"
        id="costOfGoodsSold"
        name="costOfGoodsSold"
        type="number"
        value={financialData.costOfGoodsSold}
        onChange={handleChange}
        required
      />
    </div>
    <h3 className='h3-finances'>Operating Expenses</h3>
    <div className="form-group-a">
      <label htmlFor="rent">Rent:</label>
      <input
      className="form-group-input-a"
        id="rent"
        name="operatingExpenses.rent"
        type="number"
        value={financialData.operatingExpenses.rent}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="utilities">Utilities:</label>
      <input
      className="form-group-input-a"
        id="utilities"
        name="operatingExpenses.utilities"
        type="number"
        value={financialData.operatingExpenses.utilities}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="marketing">Marketing:</label>
      <input
      className="form-group-input-a"
        id="marketing"
        name="operatingExpenses.marketing"
        type="number"
        value={financialData.operatingExpenses.marketing}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="other">Other Operating Expenses:</label>
      <input
      className="form-group-input-a"
        id="other"
        name="operatingExpenses.other"
        type="number"
        value={financialData.operatingExpenses.other}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="otherIncome">Other Income:</label>
      <input
      className="form-group-input-a"
        id="otherIncome"
        name="otherIncome"
        type="number"
        value={financialData.otherIncome}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="otherExpenses">Other Expenses:</label>
      <input
      className="form-group-input-a"
        id="otherExpenses"
        name="otherExpenses"
        type="number"
        value={financialData.otherExpenses}
        onChange={handleChange}
        required
      />
    </div>
    <button className='button-a' type="submit">Save Financial Data</button>
  </form>
);

const formatEmploymentType = (type) => {
  const formattingMap = {
    'fullTime': 'Full Time',
    'partTime': 'Part Time',
    'contract': 'Contract',
    'freelance': 'Freelance',
    'temporary': 'Temporary',
    'internship': 'Internship',
    'seasonal': 'Seasonal'
 };

  return formattingMap[type] || type.charAt(0).toUpperCase() + type.slice(1);
};



const EmployeeManagement = ({ employees, addEmployee, updateEmployee, deleteEmployee, modifiedBrandName }) => {
  const [newEmployee, setNewEmployee] = useState({
    name: '',
    surname: '',
    position: '',
    department: '',
    employmentType: '',
    paymentType: '',
    salary: '',
    hourlyRate: '',
    hoursPerDay: '',
    daysPerWeek: '',
    commissionRate: '',
    pieceRate: '',
    stipendAmount: '',
    retainerAmount: '',
    projectFee: '',
    performanceMetrics: '',
    startDate: '',
    endDate: '',
    endDateNA: false,
    maritalStatus: '',
    payrollSchedule: '',
    projectEndDate: '',
    projectEndDateNA: false,
  });
  const [showAddForm, setShowAddForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [hoveredEmployeeId, setHoveredEmployeeId] = useState(null);

  const handleRowClick = (employee) => {
    setSelectedEmployee(employee);
  };

  const handleCloseEmployeeDetails = () => {
    setSelectedEmployee(null);
  };

  const handleUpdateEmployee = (updatedEmployee) => {
    updateEmployee(updatedEmployee.id, updatedEmployee);
    setSelectedEmployee(null);
  };

  const handleDeleteEmployee = (employeeId) => {
    deleteEmployee(employeeId);
    setSelectedEmployee(null);
  };

  const handleNewEmployeeChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedEmployee = { ...newEmployee };

    if (type === 'checkbox') {
      updatedEmployee[name] = checked;
    } else {
      updatedEmployee[name] = value;
    }

    // Synchronize fields based on employment type and payment type
    if (name === 'employmentType') {
      updatedEmployee.endDateNA = value === 'fullTime' || value === 'partTime';
      updatedEmployee.endDate = updatedEmployee.endDateNA ? 'N/A' : '';
    }

    if (name === 'paymentType') {
      // Reset all payment-related fields
      updatedEmployee.salary = '';
      updatedEmployee.hourlyRate = '';
      updatedEmployee.commissionRate = '';
      updatedEmployee.pieceRate = '';
      updatedEmployee.stipendAmount = '';
      updatedEmployee.retainerAmount = '';
      updatedEmployee.projectFee = '';
      updatedEmployee.performanceMetrics = '';
      
      // Set relevant fields based on payment type
      switch (value) {
        case 'hourly':
          updatedEmployee.payrollSchedule = 'weekly';
          break;
        case 'salary':
          updatedEmployee.payrollSchedule = 'monthly';
          break;
        case 'commission':
          updatedEmployee.payrollSchedule = 'monthly';
          break;
        case 'pieceRate':
          updatedEmployee.payrollSchedule = 'weekly';
          break;
        case 'projectBased':
          updatedEmployee.payrollSchedule = 'onCompletion';
          break;
        default:
          updatedEmployee.payrollSchedule = '';
      }
    }

    setNewEmployee(updatedEmployee);
  };

  const handleAddEmployee = async (e) => {
    e.preventDefault();
    const employeeData = { ...newEmployee };

    // Calculate monthly salary equivalent for all payment types
    let monthlySalary = 0;
    switch (employeeData.paymentType) {
      case 'hourly':
        monthlySalary = parseFloat(employeeData.hourlyRate) * parseFloat(employeeData.hoursPerDay) * parseFloat(employeeData.daysPerWeek) * 4.33;
        break;
      case 'salary':
        monthlySalary = parseFloat(employeeData.salary);
        break;
      case 'commission':
        monthlySalary = parseFloat(employeeData.salary) * (1 + parseFloat(employeeData.commissionRate) / 100);
        break;
      case 'pieceRate':
        // Assuming an average production rate, this should be adjusted based on actual data
        monthlySalary = parseFloat(employeeData.pieceRate) * 1000; // Assuming 1000 pieces per month
        break;
      case 'projectBased':
        // Distribute project fee over the project duration
        const startDate = new Date(employeeData.startDate);
        const endDate = new Date(employeeData.projectEndDate);
        const monthDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
        monthlySalary = parseFloat(employeeData.projectFee) / (monthDiff || 1); // Use 1 if monthDiff is 0
        break;
      default:
        monthlySalary = 0;
    }

    employeeData.calculatedMonthlySalary = monthlySalary.toFixed(2);

    await addEmployee(employeeData);
    setNewEmployee({
      name: '',
      surname: '',
      position: '',
      department: '',
      employmentType: '',
      paymentType: '',
      salary: '',
      hourlyRate: '',
      hoursPerDay: '',
      daysPerWeek: '',
      commissionRate: '',
      pieceRate: '',
      stipendAmount: '',
      retainerAmount: '',
      projectFee: '',
      performanceMetrics: '',
      startDate: '',
      endDate: '',
      endDateNA: false,
      maritalStatus: '',
      payrollSchedule: '',
      projectEndDate: '',
      projectEndDateNA: false,
    });
    setShowAddForm(false);
  };

  const handleMouseEnter = (employeeId) => {
    setHoveredEmployeeId(employeeId);
  };

  const handleMouseLeave = () => {
    setHoveredEmployeeId(null);
  };

  const filteredEmployees = employees.filter(employee => {
    const fullName = `${employee.name} ${employee.surname}`.toLowerCase();
    const searchTerms = searchTerm.toLowerCase().split(' ');
    return searchTerms.every(term => 
      fullName.includes(term) ||
      employee.position.toLowerCase().includes(term) ||
      employee.department.toLowerCase().includes(term)
    );
  });

  if (selectedEmployee) {
    return (
      <EmployeeDetailsForm
        employee={selectedEmployee}
        modifiedBrandName={modifiedBrandName}
        onClose={handleCloseEmployeeDetails}
        onUpdate={handleUpdateEmployee}
        onDelete={handleDeleteEmployee}
      />
    );
  }

  const calculateMonthlyEquivalent = (employee) => {
    switch (employee.paymentType) {
      case 'hourly':
        return parseFloat(employee.hourlyRate) * parseFloat(employee.hoursPerDay) * parseFloat(employee.daysPerWeek) * 4.33; // 4.33 weeks per month on average
      case 'salary':
      case 'commission':
        return parseFloat(employee.salary);
      case 'pieceRate':
        // Assuming an average production rate, adjust as needed
        return parseFloat(employee.pieceRate) * parseFloat(employee.averagePiecesPerMonth || 0);
      case 'projectBased':
        // Distribute project fee over the project duration
        const startDate = new Date(employee.startDate);
        const endDate = new Date(employee.endDate || employee.projectEndDate);
        const monthDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth();
        return parseFloat(employee.projectFee) / (monthDiff || 1); // Use 1 if monthDiff is 0
      default:
        return 0;
    }
  };

  const formatSalary = (employee) => {
    const monthlyEquivalent = calculateMonthlyEquivalent(employee);
    let amount;

    switch (employee.payrollSchedule) {
      case 'weekly':
        amount = (monthlyEquivalent / 4.33).toFixed(2);
        break;
      case 'biweekly':
        amount = (monthlyEquivalent / 2.17).toFixed(2);
        break;
      case 'semimonthly':
        amount = (monthlyEquivalent / 2).toFixed(2);
        break;
      case 'monthly':
        amount = monthlyEquivalent.toFixed(2);
        break;
      case 'onCompletion':
        amount = parseFloat(employee.projectFee).toFixed(2);
        break;
      default:
        amount = monthlyEquivalent.toFixed(2);
    }

    switch (employee.paymentType) {
      case 'hourly':
        return `$${amount} (Based on $${employee.hourlyRate}/hour)`;
      case 'salary':
        return `$${amount}`;
      case 'commission':
        return `$${amount} + ${employee.commissionRate}% commission`;
      case 'pieceRate':
        return `$${amount} (Based on $${employee.pieceRate}/piece)`;
      case 'projectBased':
        return `$${employee.projectFee} (Total Project Fee)`;
      case 'unpaid':
        return 'Unpaid';
      default:
        return 'N/A';
    }
  };

  const formatPayrollSchedule = (schedule) => {
    const scheduleMap = {
      'weekly': 'Weekly',
      'biweekly': 'Bi-weekly',
      'semimonthly': 'Semi-monthly',
      'monthly': 'Monthly',
      'onCompletion': 'On Completion'
    };
    return scheduleMap[schedule] || schedule;
  };

  return (
    <div className="employee-management">
      {!showAddForm ? (
        <>
          <h2 className='h2-finances'>Current Employees</h2>
          <div className="employee-management-header">
            <div className="search-bar">
              <input
                className="form-group-input-a"
                type="text"
                placeholder="Search employees by name, position, or department..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <button className='button-c' onClick={() => setShowAddForm(true)}>
              Add New Employee
            </button>
          </div>
          <div className="table-responsive">
            <table className="employee-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Position</th>
                  <th>Department</th>
                  <th>Employment Type</th>
                  <th>Payroll Schedule</th>
                  <th>Compensation</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                </tr>
              </thead>
              <tbody>
                {filteredEmployees.map((employee) => (
                  <tr
                    key={employee.id}
                    onClick={() => handleRowClick(employee)}
                    onMouseEnter={() => handleMouseEnter(employee.id)}
                    onMouseLeave={handleMouseLeave}
                    style={{ position: 'relative' }}
                  >
                    <td data-label="Name">{`${employee.name} ${employee.surname}`}</td>
                    <td data-label="Position">{employee.position}</td>
                    <td data-label="Department">{employee.department}</td>
                    <td data-label="Employment Type">{employee.employmentType}</td>
                    <td data-label="Payroll Schedule">{formatPayrollSchedule(employee.payrollSchedule)}</td>
                    <td data-label="Compensation">{formatSalary(employee)}</td>
                    <td data-label="Start Date">{employee.startDate}</td>
                    <td data-label="End Date">{employee.endDate || 'N/A'}</td>
                    {hoveredEmployeeId === employee.id && (
                      <td
                        className="hover-message"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: '#012c59',
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: 1,
                        }}
                      >
                        Click to view profile information
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
    <div className="add-employee-section">
          <h2 className='h2-finances'>Add New Employee</h2>
          <form onSubmit={handleAddEmployee} className="add-employee-form">
            {/* Basic Information */}
            <div className="form-group-a">
              <label htmlFor="name">Name:</label>
              <input
                className="form-group-input-a"
                id="name"
                name="name"
                value={newEmployee.name}
                onChange={handleNewEmployeeChange}
                required
              />
            </div>
            <div className="form-group-a">
              <label htmlFor="surname">Surname:</label>
              <input
                className="form-group-input-a"
                id="surname"
                name="surname"
                value={newEmployee.surname}
                onChange={handleNewEmployeeChange}
                required
              />
            </div>
            <div className="form-group-a">
              <label htmlFor="maritalStatus">Marital Status:</label>
              <select
                className="form-group-input-a"
                id="maritalStatus"
                name="maritalStatus"
                value={newEmployee.maritalStatus}
                onChange={handleNewEmployeeChange}
                required
              >
                <option value="">Select Marital Status</option>
                <option value="single">Single</option>
                <option value="marriedJoint">Married Filing Jointly</option>
                <option value="marriedSeparate">Married Filing Separately</option>
                <option value="headOfHousehold">Head of Household</option>
                <option value="widower">Qualifying Widow(er) with Dependent Child</option>
                <option value="n/a">N/A</option>
              </select>
            </div>
            <div className="form-group-a">
              <label htmlFor="position">Position:</label>
              <input
                className="form-group-input-a"
                id="position"
                name="position"
                value={newEmployee.position}
                onChange={handleNewEmployeeChange}
                required
              />
            </div>
            <div className="form-group-a">
              <label htmlFor="department">Department:</label>
              <input
                className="form-group-input-a"
                id="department"
                name="department"
                value={newEmployee.department}
                onChange={handleNewEmployeeChange}
                required
              />
            </div>
            <div className="form-group-a">
              <label htmlFor="employmentType">Employment Type:</label>
              <select
                className="form-group-input-a"
                id="employmentType"
                name="employmentType"
                value={newEmployee.employmentType}
                onChange={handleNewEmployeeChange}
                required
              >
                <option value="">Select Employment Type</option>
                <option value="fullTime">Full-time</option>
                <option value="partTime">Part-time</option>
                <option value="contract">Contract</option>
                <option value="freelance">Freelance</option>
                <option value="temporary">Temporary</option>
                <option value="internship">Internship</option>
                <option value="seasonal">Seasonal</option>
              </select>
            </div>
            <div className="form-group-a">
              <label htmlFor="paymentType">Payment Type:</label>
              <select
                className="form-group-input-a"
                id="paymentType"
                name="paymentType"
                value={newEmployee.paymentType}
                onChange={handleNewEmployeeChange}
                required
              >
                <option value="">Select Payment Type</option>
                <option value="hourly">Hourly</option>
                <option value="salary">Salary</option>
                <option value="commission">Commission</option>
                <option value="pieceRate">Piece Rate</option>
                <option value="projectBased">Project-based</option>
              </select>
            </div>

            {/* Conditional fields based on payment type */}
            {newEmployee.paymentType === 'hourly' && (
              <>
                <div className="form-group-a">
                  <label htmlFor="hourlyRate">Hourly Rate:</label>
                  <input
                    className="form-group-input-a"
                    id="hourlyRate"
                    name="hourlyRate"
                    type="number"
                    value={newEmployee.hourlyRate}
                    onChange={handleNewEmployeeChange}
                    required
                  />
                </div>
                <div className="form-group-a">
                  <label htmlFor="hoursPerDay">Hours per Day:</label>
                  <input
                    className="form-group-input-a"
                    id="hoursPerDay"
                    name="hoursPerDay"
                    type="number"
                    value={newEmployee.hoursPerDay}
                    onChange={handleNewEmployeeChange}
                    required
                  />
                </div>
                <div className="form-group-a">
                  <label htmlFor="daysPerWeek">Days per Week:</label>
                  <input
                    className="form-group-input-a"
                    id="daysPerWeek"
                    name="daysPerWeek"
                    type="number"
                    value={newEmployee.daysPerWeek}
                    onChange={handleNewEmployeeChange}
                    required
                  />
                </div>
              </>
            )}
      {newEmployee.paymentType === 'salary' && (
           <div className="form-group-a">
           <label htmlFor="salary">Monthly Salary:</label>
           <input
             className="form-group-input-a"
             id="salary"
             name="salary"
             type="number"
             value={newEmployee.salary}
             onChange={handleNewEmployeeChange}
             required
           />
         </div>
       )}

       {newEmployee.paymentType === 'commission' && (
         <>
           <div className="form-group-a">
             <label htmlFor="salary">Base Salary:</label>
             <input
               className="form-group-input-a"
               id="salary"
               name="salary"
               type="number"
               value={newEmployee.salary}
               onChange={handleNewEmployeeChange}
               required
             />
           </div>
           <div className="form-group-a">
             <label htmlFor="commissionRate">Commission Rate (%):</label>
             <input
               className="form-group-input-a"
               id="commissionRate"
               name="commissionRate"
               type="number"
               value={newEmployee.commissionRate}
               onChange={handleNewEmployeeChange}
               required
             />
           </div>
         </>
       )}

       {newEmployee.paymentType === 'pieceRate' && (
         <div className="form-group-a">
           <label htmlFor="pieceRate">Rate per Piece:</label>
           <input
             className="form-group-input-a"
             id="pieceRate"
             name="pieceRate"
             type="number"
             value={newEmployee.pieceRate}
             onChange={handleNewEmployeeChange}
             required
           />
         </div>
       )}

       {newEmployee.paymentType === 'projectBased' && (
         <>
           <div className="form-group-a">
             <label htmlFor="projectFee">Project Fee:</label>
             <input
               className="form-group-input-a"
               id="projectFee"
               name="projectFee"
               type="number"
               value={newEmployee.projectFee}
               onChange={handleNewEmployeeChange}
               required
             />
           </div>
           <div className="form-group-a">
             <label htmlFor="projectEndDate">Project End Date:</label>
             <input
               className="form-group-input-a"
               id="projectEndDate"
               name="projectEndDate"
               type="date"
               value={newEmployee.projectEndDate}
               onChange={handleNewEmployeeChange}
               required
             />
           </div>
         </>
       )}

       <div className="form-group-a">
         <label htmlFor="payrollSchedule">Payroll Schedule:</label>
         <select
           className="form-group-input-a"
           id="payrollSchedule"
           name="payrollSchedule"
           value={newEmployee.payrollSchedule}
           onChange={handleNewEmployeeChange}
           required
         >
           <option value="">Select Payroll Schedule</option>
           <option value="weekly">Weekly</option>
           <option value="biweekly">Bi-weekly</option>
           <option value="semimonthly">Semi-monthly</option>
           <option value="monthly">Monthly</option>
           {newEmployee.paymentType === 'projectBased' && (
             <option value="onCompletion">On Project Completion</option>
           )}
         </select>
       </div>

       <div className="form-group-a">
         <label htmlFor="startDate">Start Date:</label>
         <input
           className="form-group-input-a"
           id="startDate"
           name="startDate"
           type="date"
           value={newEmployee.startDate}
           onChange={handleNewEmployeeChange}
           required
         />
       </div>

       <div className="form-group-a">
         <label htmlFor="endDate">End Date:</label>
         <input
           className="form-group-input-a"
           id="endDate"
           name="endDate"
           type="date"
           value={newEmployee.endDate}
           onChange={handleNewEmployeeChange}
           disabled={newEmployee.endDateNA}
           required={!newEmployee.endDateNA}
         />
         <label>
           <input
             type="checkbox"
             name="endDateNA"
             checked={newEmployee.endDateNA}
             onChange={handleNewEmployeeChange}
           /> N/A
         </label>
       </div>

       <div className="form-actions">
         <button className='button-a' type="submit">Add Employee</button>
         <button className='button-b' type="button" onClick={() => setShowAddForm(false)}>Cancel</button>
       </div>
     </form>
   </div>
 )}
</div>
);
};

const TaxRateForm = ({ taxRates, handleChange, handleSubmit }) => (
  <form onSubmit={handleSubmit} className="tax-rate-form">
    <h2 className='h2-finances'>Tax Rates</h2>
    <div className="form-group-a">
      <label htmlFor="incomeTax">Income Tax Rate (%):</label>
      <input
      className="form-group-input-a"
        id="incomeTax"
        name="incomeTax"
        type="number"
        step="0.01"
        value={taxRates.incomeTax}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="salesTax">Sales Tax Rate (%):</label>
      <input
      className="form-group-input-a"
        id="salesTax"
        name="salesTax"
        type="number"
        step="0.01"
        value={taxRates.salesTax}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="payrollTax">Payroll Tax Rate (%):</label>
      <input
      className="form-group-input-a"
        id="payrollTax"
        name="payrollTax"
        type="number"
        step="0.01"
        value={taxRates.payrollTax}
        onChange={handleChange}
        required
      />
    </div>
    <button className='button-a' type="submit">Save Tax Rates</button>
  </form>
);

const ReportGenerator = ({ generateFinancialReports }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [report, setReport] = useState(null);

  const handleGenerateReport = (e) => {
    e.preventDefault();
    const reportData = generateFinancialReports(new Date(startDate), new Date(endDate));
    setReport(reportData);
 };


 
  return (
    <div className="report-generator">
      <h2 className='h2-finances'>Generate Financial Report</h2>
      <form onSubmit={handleGenerateReport}>
        <div className="form-group-a">
          <label htmlFor="startDate">Start Date:</label>
          <input
          className="form-group-input-a"
            id="startDate"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
          />
        </div>
        <div className="form-group-a">
          <label htmlFor="endDate">End Date:</label>
          <input
          className="form-group-input-a"
            id="endDate"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
          />
        </div>
        <button className='button-a' type="submit">Generate Report</button>
      </form>
      {report && (
        <div className="generated-report">
          <h3 className='h3-finances'>Financial Report</h3>
          {report}
        </div>
 )}
    </div>
 );
};

export default Accounting;
