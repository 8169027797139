import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const Sitemap = () => {
  const [urls, setUrls] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const fetchUrls = async () => {
      const infoCollection = collection(db, 'info');
      const snapshot = await getDocs(infoCollection);
      const fetchedUrls = snapshot.docs.map(doc => ({
        loc: `https://info.daxtop.com/${doc.data().urlTitle}`,
        lastmod: doc.updateTime.toDate().toISOString().split('T')[0],
        changefreq: 'weekly',
        priority: '0.8'
      }));
      
      // Add static pages
      const staticUrls = [
        { loc: 'https://daxtop.com/', changefreq: 'daily', priority: '1.0' },
        { loc: 'https://info.daxtop.com/', changefreq: 'weekly', priority: '0.9' },
        // Add more static pages as needed
      ];
      
      setUrls([...staticUrls, ...fetchedUrls]);
    };

    fetchUrls();
  }, [db]);

  const generateSitemapXml = () => {
    return `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  ${urls.map(url => `
  <url>
    <loc>${url.loc}</loc>
    ${url.lastmod ? `<lastmod>${url.lastmod}</lastmod>` : ''}
    <changefreq>${url.changefreq}</changefreq>
    <priority>${url.priority}</priority>
  </url>`).join('')}
</urlset>`;
  };

  useEffect(() => {
    if (urls.length > 0) {
      const blob = new Blob([generateSitemapXml()], { type: 'application/xml' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'sitemap.xml';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [urls]);

  return null;
};

export default Sitemap;