import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import { Menu, Eye, EyeOff } from 'lucide-react';
import { getFirestore, doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import logoFinances from './Images/Finances-logo.svg';
import './Finances.css';
import MobileMenuFinances from './MobileMenuFinances.js';
import Accounting from './Accounting.js';

const INACTIVITY_TIMEOUT = 5 * 60 * 1000; // 5 minutes

const Finances = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);
    const [isPinSet, setIsPinSet] = useState(false);
    const [pinCode, setPinCode] = useState('');
    const [isLocked, setIsLocked] = useState(true);
    const [showPinModal, setShowPinModal] = useState(true);
    const [enteredPin, setEnteredPin] = useState('');
    const [pinError, setPinError] = useState('');
    const inactivityTimerRef = useRef(null);
    const [modifiedBrandName, setModifiedBrandName] = useState('');
    const [loading, setLoading] = useState(true);
    const [showPin, setShowPin] = useState(false);
    const [isPinValid, setIsPinValid] = useState(false);

    useEffect(() => {
        if (location.pathname === '/inventory/finances') {
            navigate('/inventory/finances/accounting');
        }
    }, [location, navigate]);

    useEffect(() => {
        const handleResize = () => {
          setIsMobileView(window.innerWidth <= 1200);
          if (window.innerWidth > 1200) {
            setIsMobileMenuOpen(false);
          }
        };
        
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    useEffect(() => {
        const fetchStoreData = async () => {
            const auth = getAuth();
            if (auth.currentUser) {
                const db = getFirestore();
                const storesRef = collection(db, 'stores');
                const q = query(storesRef, where('userId', '==', auth.currentUser.uid));
                const querySnapshot = await getDocs(q);
                
                if (!querySnapshot.empty) {
                    const storeData = querySnapshot.docs[0].data();
                    setModifiedBrandName(storeData.modifiedBrandName);
                    await fetchPinCode(storeData.modifiedBrandName);
                }
            }
            setLoading(false);
        };

        fetchStoreData();
    }, []);

    const fetchPinCode = async (brandName) => {
        if (!brandName) return;

        const db = getFirestore();
        const accountingRef = doc(db, `stores/${brandName}/finances/accounting`);
        const accountingDoc = await getDoc(accountingRef);
        
        if (accountingDoc.exists()) {
            const data = accountingDoc.data();
            setIsPinSet(!!data.pinCode);
            setPinCode(data.pinCode || '');
        }
    };

    useEffect(() => {
        const resetInactivityTimer = () => {
            if (inactivityTimerRef.current) {
                clearTimeout(inactivityTimerRef.current);
            }
            inactivityTimerRef.current = setTimeout(lockPage, INACTIVITY_TIMEOUT);
        };

        const handleVisibilityChange = () => {
            if (document.hidden) {
                resetInactivityTimer();
            }
        };

        const handlePageLoad = () => {
            const isUnlocked = localStorage.getItem('financesUnlocked') === 'true';
            if (!isUnlocked) {
                lockPage();
            } else {
                unlockPage();
            }
        };

        document.addEventListener('mousemove', resetInactivityTimer);
        document.addEventListener('keypress', resetInactivityTimer);
        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('load', handlePageLoad);

        // Check if it's the first time entering /inventory/finances/... routes
        if (location.pathname.startsWith('/inventory/finances/') && !localStorage.getItem('financesUnlocked')) {
            lockPage();
        }

        resetInactivityTimer();

        return () => {
            document.removeEventListener('mousemove', resetInactivityTimer);
            document.removeEventListener('keypress', resetInactivityTimer);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('load', handlePageLoad);
            if (inactivityTimerRef.current) {
                clearTimeout(inactivityTimerRef.current);
            }
        };
    }, [location.pathname]);

    const lockPage = () => {
        setIsLocked(true);
        setShowPinModal(true);
        setEnteredPin('');
        localStorage.removeItem('financesUnlocked');
    };

    const unlockPage = () => {
        setIsLocked(false);
        setShowPinModal(false);
        setEnteredPin('');
        setPinError('');
        localStorage.setItem('financesUnlocked', 'true');
    };

    const handlePinChange = (e) => {
        const newPin = e.target.value;
        setEnteredPin(newPin);
        setIsPinValid(newPin.length >= 4);

        // Attempt to unlock immediately if the PIN is correct
        if (newPin === pinCode) {
            unlockPage();
        }
    };

    const handlePinSubmit = async (e) => {
        e.preventDefault();
        if (!isPinValid) return;

        if (!modifiedBrandName) {
            setPinError('Unable to access store data. Please try again later.');
            return;
        }

        if (isPinSet) {
            if (enteredPin === pinCode) {
                unlockPage();
            } else {
                setPinError('Incorrect PIN. Please try again.');
            }
        } else {
            try {
                const db = getFirestore();
                const accountingRef = doc(db, `stores/${modifiedBrandName}/finances/accounting`);
                await setDoc(accountingRef, { pinCode: enteredPin }, { merge: true });
                setIsPinSet(true);
                setPinCode(enteredPin);
                unlockPage();
            } catch (err) {
                console.error('Error setting PIN:', err);
                setPinError('Failed to set PIN. Please try again.');
            }
        }
    };

    const handleInventoryClick = () => {
        navigate('/inventory/dashboard');
    };

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(prevState => !prevState);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 1200;
            setIsMobileView(isMobile);
            if (!isMobile) {
                setIsMobileMenuOpen(false);
            }
        };
        
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="finances-page">
            <nav className='finances-navbar'>
                <div className="navbar-container">
                    <div className="navbar-left">
                        <div className="navbar-logo">
                            <Link to="/inventory/finances/accounting">
                                <img src={logoFinances} alt="DAXTOP FINANCES" />
                            </Link>
                        </div>
                        {!isMobileView && (
                            <ul className="navbar-links">
                                <li><Link to="/inventory/finances/accounting" className={isActive('/inventory/finances/accounting')}> Accounting</Link></li>
                                <li><Link to="/inventory/finances/budgeting" className={isActive('/inventory/finances/budgeting')}> Budgeting</Link></li>
                                <li><Link to="/inventory/finances/credit-management" className={isActive('/inventory/finances/credit-management')}> Credit Management</Link></li>
                                <li><Link to="/inventory/finances/payments" className={isActive('/inventory/finances/payments')}> Payments</Link></li>
                                <li><Link to="/inventory/finances/reports" className={isActive('/inventory/finances/reports')}> Financial Reports</Link></li>
                            </ul>
                        )}
                    </div>
                    <div className="navbar-actions">
                        {isMobileView ? (
                            <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                                <Menu size={24} />
                            </button>
                        ) : (
                            <button className="inventory-btn" onClick={handleInventoryClick}> Go to Inventory</button>
                        )}
                    </div>
                </div>
            </nav>
            {isMobileView && (
                <MobileMenuFinances isOpen={isMobileMenuOpen} onClose={closeMobileMenu} />
            )}
            <div className="finances-content" style={{ filter: isLocked ? 'blur(5px)' : 'none', pointerEvents: isLocked ? 'none' : 'auto' }}>
                {!isLocked && (
                    <Routes>
                        <Route path="/accounting" element={<Accounting modifiedBrandName={modifiedBrandName} />} />
                        <Route path="/budgeting" element={<h1 className='h1-finances'>Budgeting (Coming Soon)</h1>} />
                        <Route path="/credit-management" element={<h1 className='h1-finances'>Credit Management (Coming Soon)</h1>} />
                        <Route path="/payments" element={<h1 className='h1-finances'>Payments (Coming Soon)</h1>} />
                        <Route path="/reports" element={<h1 className='h1-finances'>Financial Reports (Coming Soon)</h1>} />
                    </Routes>
                )}
            </div>
            {showPinModal && (
                <div className="pin-modal-overlay">
                    <div className="pin-modal">
                        <h2 className='h2-finances'>Enter PIN for Access</h2>
                        <form onSubmit={handlePinSubmit}>
                            <div className="pin-input-container">
                                <label htmlFor="pin">PIN:</label>
                                <div className="pin-input-wrapper">
                                    <input
                                        id="pin"
                                        type={showPin ? "text" : "password"}
                                        value={enteredPin}
                                        onChange={handlePinChange}
                                        placeholder="Enter PIN"
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="toggle-pin-visibility"
                                        onClick={() => setShowPin(!showPin)}
                                    >
                                        {showPin ? <EyeOff size={18} /> : <Eye size={18} />}
                                    </button>
                                </div>
                            </div>
                            {pinError && <p className="pin-error">{pinError}</p>}
                            <button 
                                type="submit" 
                                className={`unlock-button ${!isPinValid ? 'disabled' : ''}`}
                                disabled={!isPinValid}
                            >
                                Unlock
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Finances;