import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, Search } from 'lucide-react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import {app, db} from './firebaseConfig'
import logoInfo from './Images/logoBlue.webp';
import './InfoPageHeader.css';

const InfoPageHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 1200);
            if (window.innerWidth > 1200) {
                setIsMobileMenuOpen(false);
            }
        };
        
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(prevState => !prevState);
    };

    const handleSearch = async (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
        setIsLoading(true);

        if (term.length < 2) {
            setSearchResults([]);
            setIsLoading(false);
            return;
        }

        try {
            const results = [];
            const infoRef = collection(db, 'info');
            const querySnapshot = await getDocs(infoRef);

            querySnapshot.forEach(doc => {
                const data = doc.data();
                let matchFound = false;
                
                // Search in title
                if (data.title && data.title.toLowerCase().includes(term)) {
                    results.push({ id: doc.id, title: data.title });
                    matchFound = true;
                }

                // Search in subheaders
                if (!matchFound && data.sections) {
                    for (const section of data.sections) {
                        if (section.subheader.toLowerCase().includes(term)) {
                            results.push({ id: doc.id, title: data.title });
                            break;
                        }
                    }
                }
            });

            console.log('Search results:', results);
            setSearchResults(results);
        } catch (error) {
            console.error("Error searching Firestore:", error);
            setSearchResults([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleResultClick = (result) => {
        navigate(`/${result.id}`);
        setSearchTerm('');
        setSearchResults([]);
    };

    return (
        <nav className='info-navbar'>
            <div className="navbar-container">
                <div className="navbar-left">
                    <div className="navbar-logo">
                        <Link to="/inventory/info">
                            <img src={logoInfo} alt="DAXTOP INFO" />
                        </Link>
                    </div>
                    {!isMobileView && (
                        <ul className="navbar-links">
                            <li><Link to="/inventory/info/company" className={isActive('/inventory/info/company')}>Company</Link></li>
                            <li><Link to="/inventory/info/products" className={isActive('/inventory/info/products')}>Products</Link></li>
                            <li><Link to="/inventory/info/customers" className={isActive('/inventory/info/customers')}>Customers</Link></li>
                            <li><Link to="/inventory/info/suppliers" className={isActive('/inventory/info/suppliers')}>Suppliers</Link></li>
                            <li><Link to="/inventory/info/employees" className={isActive('/inventory/info/employees')}>Employees</Link></li>
                        </ul>
                    )}
                </div>
                <div className="navbar-actions">
                    {isMobileView ? (
                        <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                            <Menu size={24} />
                        </button>
                    ) : (
                        <div className="search-container">
                            <input
                                type="text"
                                placeholder="Search documents..."
                                value={searchTerm}
                                onChange={handleSearch}
                                className="search-input"
                            />
                            <Search size={20} className="search-icon" />
                            {isLoading && <div className="search-loading">Loading...</div>}
                            {!isLoading && searchResults.length > 0 && (
                                <ul className="search-results">
                                    {searchResults.map((result) => (
                                        <li key={result.id} onClick={() => handleResultClick(result)}>
                                            <span className="result-title">{result.title}</span>
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {!isLoading && searchTerm.length >= 2 && searchResults.length === 0 && (
                                <div className="no-results">No results found</div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {isMobileView && isMobileMenuOpen && (
                <div className="mobile-menu">
                    <ul>
                        <li><Link to="/inventory/info/company" onClick={() => setIsMobileMenuOpen(false)}>Company</Link></li>
                        <li><Link to="/inventory/info/products" onClick={() => setIsMobileMenuOpen(false)}>Products</Link></li>
                        <li><Link to="/inventory/info/customers" onClick={() => setIsMobileMenuOpen(false)}>Customers</Link></li>
                        <li><Link to="/inventory/info/suppliers" onClick={() => setIsMobileMenuOpen(false)}>Suppliers</Link></li>
                        <li><Link to="/inventory/info/employees" onClick={() => setIsMobileMenuOpen(false)}>Employees</Link></li>
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default InfoPageHeader;