import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc, getDocs, query, where, doc, setDoc, getDoc, onSnapshot } from 'firebase/firestore';
import { ArrowUpDown } from 'lucide-react';
import './Orders.css';

const OrderCheckIn = ({ order, modifiedBrandName }) => {
  const [showTable, setShowTable] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [items, setItems] = useState(order.items);
  const [originalItems, setOriginalItems] = useState(order.items);
  const [showScanButton, setShowScanButton] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
    setOriginalItems([...items]);
  };

  const handleSave = async () => {
    try {
      const db = getFirestore();
      const orderRef = doc(db, `stores/${modifiedBrandName}/orders/${order.id}`);
      await setDoc(orderRef, { ...order, items }, { merge: true });
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleCancel = () => {
    setItems([...originalItems]);
    setIsEditing(false);
    setShowScanButton(false);
  };

  const handleProceed = () => {
    setShowScanButton(true);
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index] = { ...updatedItems[index], [field]: value };
    setItems(updatedItems);
  };

  return (
    <div className="check-in-container">
      <div className="check-in-actions">
        {!showScanButton && !isEditing && (
          <>
            <button className="proceed-button" onClick={handleProceed}>
              Proceed
            </button>
            <button className="edit-button" onClick={handleEdit}>
              Edit
            </button>
          </>
        )}
        {isEditing && (
          <>
            <button className="save-button" onClick={handleSave}>
              Save
            </button>
            <button className="cancel-button" onClick={handleCancel}>
              Cancel
            </button>
          </>
        )}
        {showScanButton && (
          <>
          <button className="scan-button">Scan</button>
          <button className="cancel-button" onClick={handleCancel}>
          Cancel
        </button>
          </>
        )}
      </div>
      <div className="table-container">
        <table className="check-in-table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Quantity</th>
              <th>Volume</th>
              <th>Weight</th>
              <th>Barcode</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>
                  {isEditing ? (
                    <input
                      type="text"
                      value={item.name}
                      onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                    />
                  ) : (
                    item.name
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <input
                      type="number"
                      value={item.quantity}
                      onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                    />
                  ) : (
                    item.quantity
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <div className="measure-input">
                      <input
                        type="number"
                        value={item.volume}
                        onChange={(e) => handleItemChange(index, 'volume', e.target.value)}
                      />
                      <select
                        value={item.unitOfMeasureForVolume}
                        onChange={(e) => handleItemChange(index, 'unitOfMeasureForVolume', e.target.value)}
                      >
                        <option value="N/A">N/A</option>
                        <option value="Liter (L)">Liter (L)</option>
                        <option value="Milliliter (mL)">Milliliter (mL)</option>
                        <option value="Fluid Ounce (fl oz)">Fluid Ounce (fl oz)</option>
                        <option value="Cubic Meter (m³)">Cubic Meter (m³)</option>
                      </select>
                    </div>
                  ) : (
                    item.volume && item.unitOfMeasureForVolume !== 'N/A'
                      ? `${item.volume} ${item.unitOfMeasureForVolume}`
                      : 'N/A'
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <div className="measure-input">
                      <input
                        type="number"
                        value={item.weight}
                        onChange={(e) => handleItemChange(index, 'weight', e.target.value)}
                      />
                      <select
                        value={item.unitOfMeasureForWeight}
                        onChange={(e) => handleItemChange(index, 'unitOfMeasureForWeight', e.target.value)}
                      >
                        <option value="N/A">N/A</option>
                        <option value="Kilogram (kg)">Kilogram (kg)</option>
                        <option value="Gram (g)">Gram (g)</option>
                        <option value="Ounce (oz)">Ounce (oz)</option>
                        <option value="Pound (lb)">Pound (lb)</option>
                      </select>
                    </div>
                  ) : (
                    item.weight && item.unitOfMeasureForWeight !== 'N/A'
                      ? `${item.weight} ${item.unitOfMeasureForWeight}`
                      : 'N/A'
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <input
                      type="text"
                      value={item.barcode}
                      onChange={(e) => handleItemChange(index, 'barcode', e.target.value)}
                    />
                  ) : (
                    item.barcode || 'N/A'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const CreateOrderForm = ({ modifiedBrandName, stores, onSubmit }) => {
  const [items, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState({
    barcode: '',
    category: '',
    name: '',
    unitOfMeasureForVolume: '',
    volume: '',
    unitOfMeasureForWeight: '',
    weight: '',
    quantity: ''
  });
  const [selectedStore, setSelectedStore] = useState('');
  const [supplier, setSupplier] = useState('');
  const [expectedDelivery, setExpectedDelivery] = useState('');
  const [isDeliveryNA, setIsDeliveryNA] = useState(false);
  const [notifyBeforeValue, setNotifyBeforeValue] = useState('');
  const [notifyBeforeUnit, setNotifyBeforeUnit] = useState('days');
  const navigate = useNavigate();


  const categories = [
    'Groceries', 'Beverages', 'Household Products', 'Personal Care Items', 
    'Snacks', 'Frozen Foods', 'Health and Wellness Products', 'Electronics', 
    'Pet Supplies', 'Office Supplies', 'Raw Materials'
  ];

  const unitsOfMeasure = {
    weight: ['Kilogram (kg)', 'Gram (g)', 'Ounce (oz)', 'Pound (lb)', 'N/A'],
    volume: ['Liter (L)', 'Milliliter (mL)', 'Fluid Ounce (fl oz)', 'Cubic Meter (m³)', 'N/A']
  };

  const handleAddItem = () => {
    if (currentItem.name && currentItem.quantity) {
      setItems([...items, { ...currentItem, id: Date.now() }]);
      setCurrentItem({
        barcode: '',
        category: '',
        name: '',
        unitOfMeasureForVolume: '',
        volume: '',
        unitOfMeasureForWeight: '',
        weight: '',
        quantity: ''
      });
    }
  };

  const handleDeliveryNAToggle = () => {
    setIsDeliveryNA(!isDeliveryNA);
    if (!isDeliveryNA) {
      setExpectedDelivery('');
      setNotifyBeforeValue('');
      setNotifyBeforeUnit('days');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (items.length === 0 || !selectedStore || !supplier) return;

    const orderData = {
      items,
      store: selectedStore,
      supplier,
      dateCreated: new Date(),
      expectedDelivery: isDeliveryNA ? 'N/A' : expectedDelivery,
      notifyBefore: expectedDelivery && !isDeliveryNA && notifyBeforeValue ? {
        value: Number(notifyBeforeValue),
        unit: notifyBeforeUnit
      } : null,
      status: 'Pending',
      orderNumber: Math.floor(1000000000 + Math.random() * 9000000000).toString()
    };

    await onSubmit(orderData);
    navigate('/inventory/orders');
  };

  return (
    <div className="create-order-container">
      <h2 className='h2-orders'>Create New Order</h2>
      
      <div className="store-selection">
        <select
          value={selectedStore}
          onChange={(e) => setSelectedStore(e.target.value)}
          required
          className="store-select"
        >
          <option value="">Select Store</option>
          {stores.map(store => (
            <option key={store} value={store}>{store}</option>
          ))}
        </select>

        <input
          type="text"
          value={supplier}
          onChange={(e) => setSupplier(e.target.value)}
          placeholder="Supplier Name"
          required
          className="supplier-input"
        />
      </div>

      <div className="delivery-date-section">
        <label className="delivery-date-label">Expected Date of Delivery</label>
        <div className="date-input-container">
          <input
            type="date"
            value={expectedDelivery}
            onChange={(e) => setExpectedDelivery(e.target.value)}
            disabled={isDeliveryNA}
            min={new Date().toISOString().split('T')[0]}
            required={!isDeliveryNA}
            className="date-input"
            placeholder="Select expected delivery date"
          />
          <div className="na-toggle">
            <label className="na-label">
              <input
                type="checkbox"
                checked={isDeliveryNA}
                onChange={handleDeliveryNAToggle}
                className="na-checkbox"
              />
              <span>N/A</span>
            </label>
          </div>
        </div>
        {!isDeliveryNA && !expectedDelivery && (
          <p className="helper-text">Please select when you expect this order to arrive</p>
        )}
      </div>
      {expectedDelivery && !isDeliveryNA && (
        <div className="notify-before-section">
          <label className="notify-before-label">Notify before the Expected Date</label>
          <div className="notify-before-container">
            <div className="notify-inputs-wrapper">
              <input
                type="number"
                min="1"
                value={notifyBeforeValue}
                onChange={(e) => setNotifyBeforeValue(e.target.value)}
                placeholder="Enter value"
              />
              <select
                value={notifyBeforeUnit}
                onChange={(e) => setNotifyBeforeUnit(e.target.value)}
                className="notify-unit-select"
              >
                <option value="hours">Hours</option>
                <option value="days">Days</option>
                <option value="weeks">Weeks</option>
              </select>
            </div>
          </div>
        </div>
      )}

      <div className="item-form">
        <div className="form-row">
          <input
            type="text"
            value={currentItem.barcode}
            onChange={(e) => setCurrentItem({ ...currentItem, barcode: e.target.value })}
            placeholder="Barcode"
          />
          
          <select
            value={currentItem.category}
            onChange={(e) => setCurrentItem({ ...currentItem, category: e.target.value })}
          >
            <option value="">Select Category</option>
            {categories.map(cat => (
              <option key={cat} value={cat}>{cat}</option>
            ))}
          </select>
        </div>

        <div className="form-row">
          <input
            type="text"
            value={currentItem.name}
            onChange={(e) => setCurrentItem({ ...currentItem, name: e.target.value })}
            placeholder="Product Name"
            required
          />
          
          <input
            type="number"
            value={currentItem.quantity}
            onChange={(e) => setCurrentItem({ ...currentItem, quantity: e.target.value })}
            placeholder="Quantity"
            required
          />
        </div>

        <div className="form-row">
          <select
            value={currentItem.unitOfMeasureForVolume}
            onChange={(e) => setCurrentItem({ ...currentItem, unitOfMeasureForVolume: e.target.value })}
          >
            <option value="">Select Volume Unit</option>
            {unitsOfMeasure.volume.map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
          
          <input
            type="number"
            value={currentItem.volume}
            onChange={(e) => setCurrentItem({ ...currentItem, volume: e.target.value })}
            placeholder="Volume"
            disabled={currentItem.unitOfMeasureForVolume === "N/A"}
          />
        </div>

        <div className="form-row">
          <select
            value={currentItem.unitOfMeasureForWeight}
            onChange={(e) => setCurrentItem({ ...currentItem, unitOfMeasureForWeight: e.target.value })}
          >
            <option value="">Select Weight Unit</option>
            {unitsOfMeasure.weight.map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
          
          <input
            type="number"
            value={currentItem.weight}
            onChange={(e) => setCurrentItem({ ...currentItem, weight: e.target.value })}
            placeholder="Weight"
            disabled={currentItem.unitOfMeasureForWeight === "N/A"}
          />
        </div>

        <button type="button" onClick={handleAddItem} className="add-item-btn">
          Add Item
        </button>
      </div>

      {items.length > 0 && (
        <div className="items-list">
          <h3 className='h3-orders'>Order Items</h3>
          {items.map((item, index) => (
            <div key={item.id} className="item-card">
              <p >{item.name} - Quantity: {item.quantity}</p>
              <button
                onClick={() => setItems(items.filter((_, i) => i !== index))}
                className="remove-item-btn"
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      )}

      <button
        onClick={handleSubmit}
        className="submit-order-btn"
        disabled={items.length === 0 || !selectedStore || !supplier || (!isDeliveryNA && !expectedDelivery)}
      >
        Submit Order
      </button>
    </div>
  );
};


const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [stores, setStores] = useState([]);
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const [sortBy, setSortBy] = useState('Expected Date');
  const [sortOrder, setSortOrder] = useState('Ascending');
  const { orderNumber } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isCreateOrder = location.pathname.includes('/new');
  const isCheckIn = location.pathname.includes('_check_in');

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      fetchBrandNameAndStores(user.uid);
    }
  }, []);

  useEffect(() => {
    filterAndSortOrders();
  }, [orders, sortBy, sortOrder]);

  const fetchBrandNameAndStores = async (userId) => {
    const db = getFirestore();
    const storesRef = collection(db, 'stores');
    const q = query(storesRef, where('userId', '==', userId));
    
    onSnapshot(q, (snapshot) => {
      if (!snapshot.empty) {
        const storeData = snapshot.docs[0].data();
        setModifiedBrandName(storeData.modifiedBrandName);
        fetchNetworkStores(storeData.modifiedBrandName);
      }
    });
  };

  const fetchNetworkStores = async (brandName) => {
    const db = getFirestore();
    const networkStoresRef = collection(db, `stores/${brandName}/networkStores`);
    
    onSnapshot(networkStoresRef, (snapshot) => {
      const storeAddresses = snapshot.docs.map(doc => doc.id);
      setStores(storeAddresses);
    });
  };

  useEffect(() => {
    if (modifiedBrandName) {
      const db = getFirestore();
      const unsubscribe = onSnapshot(
        collection(db, `stores/${modifiedBrandName}/orders`),
        (snapshot) => {
          const ordersData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            dateCreated: doc.data().dateCreated?.toDate() || new Date(),
            expectedDelivery: doc.data().expectedDelivery === 'N/A' ? 'N/A' : doc.data().expectedDelivery
          }));
          setOrders(ordersData);
          setFilteredOrders(ordersData);
        }
      );
      return () => unsubscribe();
    }
  }, [modifiedBrandName]);

  const filterAndSortOrders = () => {
    let sorted = [...orders];

    sorted.sort((a, b) => {
      switch (sortBy) {
        case 'Expected Date':
          // Handle N/A values
          if (a.expectedDelivery === 'N/A' && b.expectedDelivery === 'N/A') return 0;
          if (a.expectedDelivery === 'N/A') return 1;
          if (b.expectedDelivery === 'N/A') return -1;
          return new Date(a.expectedDelivery) - new Date(b.expectedDelivery);
        
        case 'Date Added':
          return a.dateCreated - b.dateCreated;
        
        case 'Alphabetical':
          return a.supplier.localeCompare(b.supplier);
        
        default:
          return 0;
      }
    });

    if (sortOrder === 'Descending') {
      sorted = sorted.reverse();
    }

    setFilteredOrders(sorted);
  };

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => prevOrder === 'Ascending' ? 'Descending' : 'Ascending');
  };

  const handleCreateOrder = async (orderData) => {
    const db = getFirestore();
    const orderRef = doc(collection(db, `stores/${modifiedBrandName}/orders`));
    await setDoc(orderRef, {
      ...orderData,
      id: orderRef.id
    });
  };

  const handleReview = (orderId) => {
    navigate(`/inventory/orders/${orderId}`);
  };

  const handleCheckIn = (orderId) => {
    navigate(`/inventory/orders/${orderId}_check_in`);
  };

  if (isCreateOrder) {
    return (
      <CreateOrderForm
        modifiedBrandName={modifiedBrandName}
        stores={stores}
        onSubmit={handleCreateOrder}
      />
    );
  }

  if (isCheckIn && orderNumber) {
    const orderIdBase = orderNumber.split('_check_in')[0];
    const order = orders.find(o => o.id === orderIdBase);
    if (!order) return <div>Loading...</div>;
    return <OrderCheckIn order={order} modifiedBrandName={modifiedBrandName} />;
  }

  if (orderNumber) {
    const order = orders.find(o => o.id === orderNumber);
    if (!order) return <div>Loading...</div>;

    return (
      <div className="order-details">
        <h2 className='h2-orders'>Order Details: {order.orderNumber}</h2>
        <p className='p-order' >Supplier: {order.supplier}</p>
        <p className='p-order' >Store: {order.store}</p>
        <p className='p-order' >Status: {order.status}</p>
        <div className="order-items">
          {order.items.map((item, index) => (
            <div key={index} className="order-item-detail">
              <h3 className='h3-orders'>{item.name}</h3>
              <p className='p-order-card' >Quantity: {item.quantity}</p>
              <p className='p-order-card' >Category: {item.category}</p>
              {item.volume && item.unitOfMeasureForVolume !== 'N/A' && (
                <p className='p-order-card' >Volume: {item.volume} {item.unitOfMeasureForVolume}</p>
              )}
              {item.weight && item.unitOfMeasureForWeight !== 'N/A' && (
                <p className='p-order-card' >Weight: {item.weight} {item.unitOfMeasureForWeight}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  const calculateRemainingTime = (expectedDate, notifyBefore) => {
    if (!expectedDate || expectedDate === 'N/A' || !notifyBefore) return null;

    const now = new Date();
    const expected = new Date(expectedDate);
    const timeDiff = expected.getTime() - now.getTime();
    
    // Convert notification period to milliseconds
    let notifyMilliseconds;
    switch (notifyBefore.unit) {
      case 'hours':
        notifyMilliseconds = notifyBefore.value * 60 * 60 * 1000;
        break;
      case 'days':
        notifyMilliseconds = notifyBefore.value * 24 * 60 * 60 * 1000;
        break;
      case 'weeks':
        notifyMilliseconds = notifyBefore.value * 7 * 24 * 60 * 60 * 1000;
        break;
      default:
        return null;
    }

    // If we're within the notification period
    if (timeDiff <= notifyMilliseconds && timeDiff > 0) {
      // Calculate remaining time
      const hours = Math.floor(timeDiff / (60 * 60 * 1000));
      const days = Math.floor(hours / 24);
      const weeks = Math.floor(days / 7);

      if (weeks > 0) {
        return `Remaining ${weeks} week${weeks > 1 ? 's' : ''}`;
      } else if (days > 0) {
        return `Remaining ${days} day${days > 1 ? 's' : ''}`;
      } else {
        return `Remaining ${hours} hour${hours > 1 ? 's' : ''}`;
      }
    }

    return null;
  };


  return (
    <div className="orders-container">
      <div className="orders-header">
        <div className="sort-section">
          <span>Sort by:</span>
          <select 
            value={sortBy} 
            onChange={(e) => setSortBy(e.target.value)}
            className="sort-select"
          >
            <option value="Expected Date">Expected Date</option>
            <option value="Date Added">Date Added</option>
            <option value="Alphabetical">Alphabetical</option>
          </select>
          <button onClick={toggleSortOrder} className="sort-order-button">
            <ArrowUpDown size={20} />
            {sortOrder}
          </button>
        </div>
        <button
          className="new-order-btn"
          onClick={() => navigate('/inventory/orders/new')}
        >
          New Order
        </button>
      </div>

      <div className="orders-list">
        {filteredOrders.map((order) => (
          <div key={order.id} className="order-item">
            <div className="order-info">
              <span>N: {order.orderNumber} [{order.supplier}]</span>
              <span className="order-date">
                Expected: {order.expectedDelivery}
                {order.expectedDelivery !== 'N/A' && order.notifyBefore && (
                  <span className="remaining-time">
                    {calculateRemainingTime(order.expectedDelivery, order.notifyBefore)}
                  </span>
                )}
              </span>
            </div>
            <div className="order-actions">
              <button
                className="check-in-btn"
                onClick={() => handleCheckIn(order.id)}
              >
                Check in
              </button>
              <button
                className="review-btn"
                onClick={() => handleReview(order.id)}
              >
                Review
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Orders;