import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import "./InfoPage.css";
import InfoPageHeader from './InfoPageHeader';

const InfoPage = () => {
  const { title } = useParams();
  const [pageData, setPageData] = useState(null);
  const db = getFirestore();
  const [activeSection, setActiveSection] = useState(null);
  const sectionRefs = useRef([]);
  const observerRef = useRef(null);

  useEffect(() => {
    const fetchPageData = async () => {
      const decodedTitle = decodeURIComponent(title);
      const infoCollection = collection(db, 'info');
      const q = query(infoCollection, where('urlTitle', '==', decodedTitle));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        setPageData(querySnapshot.docs[0].data());
      } else {
        console.log("No such document!");
      }
    };
    fetchPageData();
  }, [title, db]);

  useEffect(() => {
    if (pageData) {
      sectionRefs.current = sectionRefs.current.slice(0, pageData.sections.length);
    }
  }, [pageData]);

  useEffect(() => {
    if (pageData && sectionRefs.current.length) {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const index = sectionRefs.current.findIndex(ref => ref === entry.target);
              setActiveSection(index);
            }
          });
        },
        { threshold: 0.5 }
      );

      sectionRefs.current.forEach((ref) => {
        if (ref) observerRef.current.observe(ref);
      });

      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      };
    }
  }, [pageData]);

  const scrollToSection = (index) => {
    setActiveSection(index);
    sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
  };

  if (!pageData) {
    return <div>Loading...</div>;
  }

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": pageData.title,
    "description": pageData.sections[0].paragraph.substring(0, 150),
    "author": {
      "@type": "Organization",
      "name": "Daxtop"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Daxtop",
      "logo": {
        "@type": "ImageObject",
        "url": "https://info.daxtop.com/logo.png"
      }
    },
    "datePublished": new Date().toISOString(),
    "dateModified": new Date().toISOString()
  };

  const metaDescription = `${pageData.title} | Daxtop Business Intelligence Solutions: ${pageData.sections[0].paragraph.substring(0, 150)}...`;
  const keywords = `${pageData.title}, ${pageData.sections.map(section => section.subheader).join(', ')}, Daxtop, Business Intelligence, Software Solutions`;
  const canonicalUrl = `https://info.daxtop.com/${pageData.urlTitle}`;

  return (
    <div className="info-page-wrapper">
      <Helmet>
        <title>{pageData.title} | Daxtop Info</title>
        <meta name="description" content={`Learn about ${pageData.title}. ${pageData.sections[0].paragraph.substring(0, 150)}...`} />
        <meta property="og:title" content={`${pageData.title} | Daxtop Info`} />
        <meta property="og:description" content={`Learn about ${pageData.title}. ${pageData.sections[0].paragraph.substring(0, 150)}...`} />
        <meta property="og:url" content={`https://info.daxtop.com/${pageData.urlTitle}`} />
        <meta property="og:type" content="article" />
        <link rel="canonical" href={`https://info.daxtop.com/${pageData.urlTitle}`} />
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
      </Helmet>
      <div className="info-page-header">
        <InfoPageHeader />
      </div>
      <main>
        <h1 className="page-title">{pageData.title}</h1>
        <div className="info-page-content">
          <nav className="sidebar">
            <h2>Contents</h2>
            <ul>
              {pageData.sections.map((section, index) => (
                <li 
                  key={index} 
                  className={activeSection === index ? 'active' : ''}
                  onClick={() => scrollToSection(index)}
                >
                  {section.subheader}
                </li>
              ))}
            </ul>
          </nav>
          <article className="main-content">
            <div className="sections-container">
              {pageData.sections.map((section, index) => (
                <section 
                  key={index} 
                  className="section" 
                  ref={el => sectionRefs.current[index] = el}
                >
                  <h2>{section.subheader}</h2>
                  <p>{section.paragraph}</p>
                </section>
              ))}
            </div>
          </article>
        </div>
      </main>
    </div>
  );
};

export default InfoPage;